:root {
	--en-font-family: 'Hedvig Letters Serif', serif;
	--ko-font-family: 'Noto Serif KR', serif;
	--ja-font-family: 'Noto Serif JP', serif;
}

body {
	margin: 0;
	padding: 0;
	font-family: var(--en-font-family);
}

[lang="en"] {
	font-family: var(--en-font-family);
}
[lang="ko"] {
	font-family: var(--ko-font-family);
}
[lang="ja"] {
	font-family: var(--ja-font-family);
}

a {
	color: #000;
}

a:hover {
	color: #333;
}

/* Default Calendar Styles */
multi-year-calendar::part(year)::selection,
multi-year-calendar::part(month)::selection,
multi-year-calendar::part(day)::selection,
multi-year-calendar::part(date)::selection {
	background-color: yellow;
}
multi-year-calendar::part(calendar locale-en) {
	font-family: var(--en-font-family);
}
multi-year-calendar::part(calendar locale-ko) {
	font-family: var(--ko-font-family);
}
multi-year-calendar::part(calendar locale-ja) {
	font-family: var(--ja-font-family);
}